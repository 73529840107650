export const baseUrl = `https://www.mdfirstresearch.com/`;

// export const baseUrl = `http://localhost:4000/`;


export const addstudies = `${baseUrl}studiesForm/createForm`;
export const signup = `${baseUrl}login/signup`;
export const login = `${baseUrl}login/login`;
export const createBlog = `${baseUrl}blog/createBlog`;
export const getAllBlog = `${baseUrl}blog/getAllBlog`;
export const getBlogById = `${baseUrl}blog/getBlogById`;
export const updateBlog = `${baseUrl}blog/updateBlog`;
export const deleteBlog = `${baseUrl}blog/deleteBlog/`;
export const updateStudies = `${baseUrl}studiesForm/updateForm`;
export const deleteStudies = `${baseUrl}studiesForm/deleteForm/`;
export const createLocation = `${baseUrl}location/createLocation`;
export const getAllLocation = `${baseUrl}location/getAllLocation`;
export const getLocationById = `${baseUrl}location/getLocationById`;
export const updateLocation = `${baseUrl}location/updateLocation`;
export const deleteLocation = `${baseUrl}location/deleteLocation/`;
export const getAllStudies = `${baseUrl}studiesForm/getAllStudies`;
export const getStudiesById = `${baseUrl}studiesForm/getStudiesById`;
export const contactUs = `${baseUrl}contact/contact`;

export const addEvent = `${baseUrl}event/createEvent`;
export const getAllEvents = `${baseUrl}event/getAllEvents`;
export const deleteEvent = `${baseUrl}event/deleteEvent/`;
export const getEventById = `${baseUrl}event/getEventById`;
export const updateEvent = `${baseUrl}event/updateEvent`;

export const TotalStudies = `${baseUrl}api/total-studies`;
export const TotalBlog = `${baseUrl}api/total-blogs`;
export const TotaInvestigator = `${baseUrl}api/total-investigator`;
export const TotaOffice = `${baseUrl}api/total-officeimage`;
export const TotaEvent = `${baseUrl}api/total-event`;
export const TotaGallery = `${baseUrl}api/total-gallery`;

export const createInvestigator = `${baseUrl}Investigator/createInvestigator`;
export const getAllInvestigator = `${baseUrl}Investigator/getAllInvestigator`;
export const deleteInvestigator = `${baseUrl}Investigator/deleteInvestigator/`;
export const updateInvestigator = `${baseUrl}Investigator/updateInvestigator/`;
export const getInvestigatorById = `${baseUrl}Investigator/getInvestigatorById/`;

export const createImage = `${baseUrl}Image/createImage`;
export const getAllImage = `${baseUrl}Image/getAllImage`;
export const getImageBYID = `${baseUrl}Image/getImageBYID/`;
export const deleteImage = `${baseUrl}Image/deleteImage/`;
export const updateImage = `${baseUrl}Image/updateImage/`;

export const createRecent = `${baseUrl}recent/createRecent`;
export const getAllRecent = `${baseUrl}recent/getAllRecent`;
export const getRecentById = `${baseUrl}recent/getRecentById`;
export const updateRecent = `${baseUrl}recent/updateRecent`;
export const deleteRecent = `${baseUrl}recent/deleteRecent/`;

export const studiesContact = `${baseUrl}studies/studies`;
export const LocationContact = `${baseUrl}location/contact`;
export const sponsorsContact = `${baseUrl}sponsors/sponsors-cro`;
export const referralContact = `${baseUrl}referral/referrals`;

export const creategallery = `${baseUrl}gallery/createImage`;
export const getALLgallery = `${baseUrl}gallery/getAllImage`;
export const deletegalleryBYID = `${baseUrl}gallery/deleteImage/`;
export const updategallery = `${baseUrl}gallery/updateImage/`;
export const getGalleryBYID = `${baseUrl}gallery/getImageById/`;
