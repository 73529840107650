import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import '../About/About.css';
import './Gallery.css';
import { baseUrl, getALLgallery } from '../../Utils/api';

const Gallery = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(getALLgallery);
                const imageData = response.data.data.map(study => ({
                    url: `${baseUrl}${study.image}`, // Build the full URL for the image
                    desc: study.desc || study.desc || 'No description available' // Handle both 'description' and 'desc', with a fallback
                }));
                setGalleryImages(imageData); // Set the state with the array of image data
            } catch (error) {
                console.log('Error fetching data', error);
            }
        };
    
        fetchImages();
    }, []);




    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col aboutus__bg aboutus_title'>
                    <h2 className='fs-1 fw-bold text-light text-center'>Our Gallery</h2>
                </div>
            </div>

            <div className="image-gallery bg-gray-200">
                {galleryImages.map((image, index) => ( // Map through the images to display each image with title/description
                    <div key={index} className="image-gallery__item">
                        <img 
                        src={image.url} 
                        alt={`Gallery Item ${index + 1}`} 
                        className="image-gallery__img" 
                        onClick={() => setSelectedImage(image.url)}
                        />
                  
                  
                  <div className="absolute bottom-0 bg-black bg-opacity-50 text-white px-2 py-1 w-full text-center hidden lg:block lg:text-xs lg:py-0.5 xl:text-sm xl:py-1">
  {image.desc}
</div>

                  
                    </div>
                ))}
            </div>


   {/* Full-Size Image Modal */}





{selectedImage && (
  <div
    className="fixed lg:mt-4 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4"
    onClick={() => setSelectedImage(null)}
  >
    <div
      className="relative bg-white p-4 rounded-lg shadow-lg flex justify-center items-center 
                 w-[90%] sm:w-3/4 md:w-2/3 lg:w-1/2 max-h-[90%] sm:h-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="absolute top-2 right-2 text-gray-600 text-2xl font-bold bg-white p-2 rounded-full shadow-md"
        onClick={() => setSelectedImage(null)}
      >
        &times;
      </button>

      <img
        src={selectedImage}
        alt="Full Size"
        className="max-w-full max-h-[80vh] object-contain rounded-lg"
      />
    </div>
  </div>
)}





        </div>
    );
};

export default Gallery;



































